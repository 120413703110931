var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    {
      staticClass: "arrangement-item card card--portrait",
      attrs: {
        "data-test":
          "button." +
          (_vm.isBlankTemplate ? "empty_arrangement" : "arrangement"),
        tabindex: "0",
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.openNewArrangementModal.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "header",
        { staticClass: "arrangement-item__header" },
        [
          _vm.isBlankTemplate
            ? _c("a-icon", {
                staticClass: "arrangement-item__template-icon",
                attrs: { name: "file", size: "largest" },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isBlankTemplate
            ? _c("a-icon", {
                staticClass: "arrangement-item__template-icon",
                attrs: { name: "file-lines", size: "largest" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("strong", { staticClass: "arrangement-item__title-wrapper" }, [
            _c("span", {
              staticClass: "arrangement-item__title-label",
              domProps: {
                innerHTML: _vm._s(
                  _vm.isBlankTemplate
                    ? _vm.$t("new_arrangement.template.empty.title")
                    : _vm.arrangementTitle
                ),
              },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", {
        staticClass: "arrangement-item__description",
        attrs: { slot: "footer" },
        domProps: {
          innerHTML: _vm._s(
            _vm.isBlankTemplate
              ? _vm.$t("new_arrangement.template.empty.subtitle_empty")
              : _vm.$t("new_arrangement.template.empty.subtitle_predefined")
          ),
        },
        slot: "footer",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }