<template>
  <a-card @click.prevent="openNewArrangementModal"
     class="arrangement-item card card--portrait"
     :data-test="'button.' + (isBlankTemplate ? 'empty_arrangement' : 'arrangement')"
     tabindex="0">
    <header class="arrangement-item__header">
      <a-icon v-if="isBlankTemplate" name="file" size="largest" class="arrangement-item__template-icon" />
      <a-icon v-if="!isBlankTemplate" name="file-lines" size="largest" class="arrangement-item__template-icon" />
      <strong class="arrangement-item__title-wrapper">
        <span class="arrangement-item__title-label" v-html="isBlankTemplate ? $t('new_arrangement.template.empty.title') : arrangementTitle"></span>
      </strong>
    </header>
    <p slot="footer" class="arrangement-item__description"
       v-html="isBlankTemplate ? $t('new_arrangement.template.empty.subtitle_empty') : $t('new_arrangement.template.empty.subtitle_predefined')"/>
  </a-card>
</template>

<script>
  import ArrangementItem from './ArrangementItem'
  import ModalNewArrangement from './modal/ModalNewArrangement'
  import {EventBus, MODAL_OPEN_EVENT} from '../../common/EventBus'
  import {Prompt} from './modal/ModalContent'

  export default {
    name: 'ArrangementTemplateItem',

    components: {
      ModalNewArrangement
    },

    extends: ArrangementItem,

    methods: {
      openNewArrangementModal() {
        EventBus.$emit(MODAL_OPEN_EVENT, Prompt.newArrangement(this.arrangement))
      }
    }
  }
</script>
