<template>
  <div>
    <div class="pr-3 mb-3 a-modal-content arrangement-connect-groups" ref="arrangement-action">
      <loading-message v-if="state.showLoadingScreen()" :loading="true"/>
      <template v-else-if="state.showEditScreen()">
        <p class="feedback feedback--regular" v-html="headingText"></p>

        <a-select
          class="mb-2"
          :key="'group-selector-' + groupSelectorKey"
          :placeholder="$t('generic.modal.messages.select_group')"
          data-test="dropdown-select-group"
          @change="addGroupNew"
          :disabled="state.connectButtonDisabled()"
        >
          <a-select-option
            v-for="(group, index) in availableGroups"
            :key="'select_option_' + index"
            :value="group.id"
            :data-test="'dropdown-select-group-option-' + index"
          >{{ group.name }}
          </a-select-option>
        </a-select>

        <p class="text-bold" v-if="selectedGroupIds.length > 0">{{
            $t('connect.groups_selected', {
              selected: selectedGroupIds.length,
              total: store.groups.lengthCurrent
            })
          }}</p>
        <p class="text-muted" v-if="selectedGroupIds.length === 0">{{ $t('connect.no_groups_selected') }}</p>

        <transition-group tag="div" class="arrangement-connect-groups__pills-container" name="component-fade">
          <a-border-block
            v-for="selectedGroupId in selectedGroupIds"
            :key="selectedGroupId"
            type="filter"
            size="small"
            :name="getGroupLabel(selectedGroupId)"
            @close="removeGroup(selectedGroupId)"
            :disabled="state.connectButtonDisabled()"
            closeable
          >{{ getGroupLabel(selectedGroupId) }}</a-border-block>
        </transition-group>
      </template>
      <template v-else-if="state.showConfirmRemoveScreen()">
        <a-alert type="warning">{{ $t('connect.confirm_remove_connected_group') }}</a-alert>
      </template>

    </div>
    <modal-button-footer>
      <template v-if="state.showConfirmRemoveScreen()">
        <a-button
          data-test="modal.button.remove"
          @click="state.confirmRemove()"
          color="red"
        >
          <a-icon name="delete" />
          {{ $t('generic.buttons.remove') }}
        </a-button>
        <a-button
          data-test="modal.button.cancel"
          @click="state.cancelRemove()"
          color="blue"
          variant="ghost"
        >
          {{ $t('generic.buttons.cancel') }}
        </a-button>
      </template>
      <template v-else>
        <a-button
          data-test="modal.button.connect"
          :disabled="state.connectButtonDisabled()"
          @click="checkConnect"
          color="blue"
        >
          {{ $t('generic.buttons.save') }}
        </a-button>
        <a-button
          data-test="modal.button.cancel"
          :disabled="state.connectButtonDisabled()"
          @click="cancel"
          color="blue"
          variant="ghost"
          >
          {{ $t('generic.buttons.cancel') }}
        </a-button>
      </template>
    </modal-button-footer>
  </div>
</template>

<script>
import { store } from '../../../store'
import ModalButtonFooter from '../modal/ModalButtonFooter'
import CustomButton from '../../user-interface/Button'
import {
  ARRANGEMENT_GROUPS_UPDATED,
  EventBus,
  MODAL_CLOSE_EVENT,
} from '../../../common/EventBus'
import LoadingMessage from './LoadingMessage'
import { confirm } from '../modal/Dialogs'

class ConnectState {
  showLoadingScreen () {
    return false
  }

  showEditScreen () {
    return false
  }

  connectButtonDisabled () {
    return true
  }

  showConfirmRemoveScreen () {
    return false
  }

  loadGroups () {}

  removeGroup (groupId, selectedGroupIds, originalGroupIds) {}

  confirmRemove () {}

  cancelRemove () {}

  connect (arrangement, selectedGroupIds, onError) {}
}

class UnmountedState extends ConnectState {
  loadGroups (changeStateTo) {
    changeStateTo(new LoadingState(changeStateTo))
  }
}

class LoadingState extends ConnectState {
  constructor (changeStateTo) {
    super()
    store.loadGroups().then(() => {
      changeStateTo(new EditState(changeStateTo))
    })
  }

  showLoadingScreen () {
    return true
  }
}

class EditState extends ConnectState {
  constructor (changeStateTo) {
    super()
    this.changeStateTo = changeStateTo
  }

  connectButtonDisabled () {
    return false
  }

  showEditScreen () {
    return true
  }

  removeGroup (groupId, selectedGroupIds, originalGroupIds) {
    const groupIndex = selectedGroupIds.indexOf(groupId)
    if (groupIndex === -1) {
      return
    }
    const removeGroupId = () => {
      selectedGroupIds.splice(groupIndex, 1)
    }
    //check if this group was one of the original groups, if so, ask for confirmation
    if (originalGroupIds.indexOf(groupId) !== -1) {
      this.changeStateTo(new ConfirmRemovalState(removeGroupId, this.changeStateTo))
      return
    }
    removeGroupId()
  }

  connect (arrangement, selectedGroupIds, onError) {
    this.changeStateTo(new ConnectingState(this.changeStateTo, arrangement, selectedGroupIds, onError))
  }
}

class ConnectingState extends ConnectState {
  constructor (changeStateTo, arrangement, selectedGroupIds, onError) {
    super()
    this.changeStateTo = changeStateTo

    const updatedArrangement = Object.assign({}, arrangement, {
      audienceGroups: selectedGroupIds
    })
    store.updateArrangement(arrangement.code, updatedArrangement).then(() => {
      EventBus.$emit(ARRANGEMENT_GROUPS_UPDATED, arrangement.code, selectedGroupIds)
    }).catch(onError).finally(() => {
      EventBus.$emit(MODAL_CLOSE_EVENT)
    })
  }

  showEditScreen () {
    return true
  }
}

class ConfirmRemovalState extends ConnectState {
  constructor (confirmCallback, changeStateTo) {
    super()
    this.changeStateTo = changeStateTo
    this.confirmCallback = confirmCallback
  }

  showConfirmRemoveScreen () {
    return true
  }

  confirmRemove () {
    this.confirmCallback()
    this.changeStateTo(new EditState(this.changeStateTo))
  }

  cancelRemove () {
    this.changeStateTo(new EditState(this.changeStateTo))
  }
}

export default {
  data () {
    return {
      state: new UnmountedState(),
      selectedGroupIds: [],
      store,
      groupSelectorKey: 1
    }
  },

  props: {
    arrangement: Object,
    large: Boolean
  },

  components: {
    ModalButtonFooter,
    CustomButton,
    LoadingMessage
  },

  mounted () {
    this.selectedGroupIds = [...this.arrangement.audienceGroups]
    this.state.loadGroups((newState) => {
      this.state = newState
    })
  },

  watch: {
    availableGroups (value) {
      this.groupSelectorKey++;
    }
  },

  computed: {
    availableGroups () {
      return store.groups ? store.groups.available(this.selectedGroupIds) : []
    },
    headingText () {
      return !this.arrangement.audienceGroups.length ?
        this.$t('generic.modal.messages.connect_not_yet_connected', { arrangement: this.arrangement.title }) :
        this.$t('generic.modal.messages.manage_groups', { arrangement: this.arrangement.title })
    }
  },

  methods: {
    addGroupNew (event) {
      this.selectedGroupIds.push(event.detail.value);
    },
    addGroup (group) {
      this.selectedGroupIds.push(group.id)
    },
    removeGroup (groupId) {
      this.state.removeGroup(groupId, this.selectedGroupIds, this.arrangement.audienceGroups)
    },
    cancel () {
      EventBus.$emit(MODAL_CLOSE_EVENT)
    },
    checkConnect () {
      if (store.showConnectWarning(this.arrangement)) {
        confirm(
          this.$t('generic.modal.titles.not_your_arrangement'),
          this.$t('generic.modal.messages.confirm_save_not_your_arrangement'),
          () => this.connect()
        )
      } else {
        this.connect()
      }
    },
    connect () {
      this.error = ''
      this.state.connect(this.arrangement, this.selectedGroupIds, (exception) => {
        this.error = exception.message
      })
    },
    getGroupLabel (selectedGroupId) {
      const group = store.groups.get(selectedGroupId)

      if (group) {
        return group.name
      }
      return '-'
    }
  }
}
</script>
