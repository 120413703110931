var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    {
      attrs: {
        "data-test": _vm.dataTestId(
          "arrangement-card-item-",
          _vm.arrangement.title
        ),
        tabindex: "0",
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.gotoArrangement.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "card-header",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("a-icon", {
            staticClass: "card-header__icon",
            attrs: { size: "medium", name: "file-lines" },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "card-header__title",
            domProps: { innerHTML: _vm._s(_vm.arrangementTitle) },
          }),
          _vm._v(" "),
          _c("a-action-menu", { attrs: { placement: "bottom-end" } }, [
            _c(
              "div",
              { attrs: { slot: "trigger" }, slot: "trigger" },
              [
                _c("a-icon-button", {
                  attrs: {
                    variant: "ghost",
                    color: "blue",
                    icon: "ellipsis",
                    "data-test": "button.elipsis",
                    tabindex: "0",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c(
                  "a-list",
                  [
                    _c(
                      "a-list-item",
                      { attrs: { "no-border": "" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { "data-test": "ellipsis.menu.option.edit" },
                            on: {
                              click: (event) => _vm.openModal(event, "edit"),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("generic.context_menu.edit.label"))
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a-list-item",
                      { attrs: { "no-border": "" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              "data-test": "ellipsis.menu.option.duplicate",
                            },
                            on: {
                              click: (event) =>
                                _vm.openModal(event, "duplicate"),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("generic.context_menu.duplicate.label")
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a-list-item",
                      { attrs: { "no-border": "" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              "data-test": "ellipsis.menu.option.connect",
                            },
                            on: {
                              click: (event) => _vm.openModal(event, "connect"),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("generic.context_menu.connect.label")
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a-list-item",
                      { attrs: { "no-border": "" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              "data-test": "ellipsis.menu.option.delete",
                            },
                            on: {
                              click: (event) => _vm.openModal(event, "delete"),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("generic.context_menu.delete.label")
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.arrangement.description && _vm.arrangement.description.length > 0
        ? _c(
            "p",
            {
              staticClass: "card-description",
              attrs: { "data-test": "template.item.arrangement_description" },
            },
            [_vm._v(_vm._s(_vm.arrangement.description.trim()))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canLinkArrangement && !_vm.isNewArrangementView
        ? [
            _vm.groups.length === 0
              ? _c(
                  "a-button",
                  {
                    attrs: {
                      size: "small",
                      color: "blue",
                      variant: "subtle",
                      "data-test": "button.link_group",
                    },
                    on: { clicked: (event) => _vm.openModal(event, "connect") },
                  },
                  [
                    _c("a-icon", { attrs: { name: "group" } }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("generic.buttons.connect")) + "\n    "
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "card-groups" }, [
                  _c(
                    "label",
                    { staticClass: "card-groups__label" },
                    [
                      _c("a-icon", {
                        staticClass: "mr-1",
                        attrs: { name: "group" },
                      }),
                      _vm._v(
                        _vm._s(_vm.$t("list.item.connected_groups_label"))
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    [
                      _vm._l(_vm.groups, function (group, index) {
                        return [
                          _vm._v("\n          " + _vm._s(group.name)),
                          ++index < _vm.groups.length
                            ? [_vm._v(", ")]
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("div", { staticClass: "card-info" }, [
            _vm.arrangement.hasOwnProperty("creatorUser") &&
            !_vm.isArrangementOfAuthenticatedUser(_vm.arrangement)
              ? _c(
                  "div",
                  {
                    attrs: { "data-test": "arrangement-card-last-modified-by" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("list.item.owner", {
                            owner: _vm.arrangement.creatorUser,
                          })
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { "data-test": "arrangement-card-last-modified-by" } },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("generic.updated_at", {
                      date: _vm.date,
                      time: _vm.time,
                    })
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _vm.arrangement.hasOwnProperty("lastModifiedByUser") &&
            (!_vm.isArrangementOfAuthenticatedUser(_vm.arrangement) ||
              !_vm.isArrangementLastEditedByAuthenticatedUser(_vm.arrangement))
              ? _c(
                  "div",
                  {
                    attrs: { "data-test": "arrangement-card-last-modified-by" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("list.item.last_modified_by", {
                            lastModifiedBy: _vm.arrangement.lastModifiedByUser,
                          })
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.arrangement.revision > 1
            ? _c(
                "a-button",
                {
                  attrs: { size: "small", color: "blue", variant: "ghost" },
                  on: { clicked: _vm.openStream },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("generic.buttons.to_teaching_material")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }