<template>
  <div>
    <div class="pr-3 mb-3 a-modal-content">
      <modal-loading v-if="loading"></modal-loading>
      <div class="new-arrangement-container">
        <div class="new-arrangement-header">
          <span class="new-arrangement-header__icon" v-if="newArrangement.code">
            <a-icon name="file-lines" size="largest" style="color: #252525"/>
            </span>
          <span class="new-arrangement-header__label">{{ originalTitle }}</span>
        </div>
        <div class="new-arrangement-form-container mt-2">
          <a-text-input
            data-test="new-arrangement-form.title"
            type="text"
            :placeholder="$t('placeholders.new_name_label')"
            @input="setTitle"
            :value="newArrangement.title"
            :disabled="loading"
          >
            Titel
            <span slot="error">De gekozen titel is te kort</span>
          </a-text-input>
          <a-text-area
            data-test="new-arrangement-form.description"
            rows="5"
            maxlength="255"
            :placeholder="$t('placeholders.new_name_description')"
            @input="setDescription"
            :value="newArrangement.description"
            :disabled="loading"
          >
            Omschrijving
          </a-text-area>
          <warning v-if="error" :message="error"/>
        </div>
      </div>
    </div>
    <modal-button-footer>
      <custom-button
        size="medium"
        :color="'blue'"
        :disabled="loading || !valid"
        data-test="new-arrangement-form.button.submit"
        :loading="loading" @click="createArrangement">{{
          $t('generic.buttons.create_arrangement') }}
      </custom-button>
      <custom-button
        size="medium"
        :color="'blue'"
        :variant="'ghost'"
        :disabled="loading"
        data-test="new-arrangement-form.button.cancel"
        @click="cancel">{{ $t('generic.buttons.cancel') }}
      </custom-button>
    </modal-button-footer>
  </div>
</template>

<script>
  import InlineSvg from '../../../components/InlineSvg'
  import Warning from '../../../components/notification/Warning'
  import CustomButton from '../../user-interface/Button'
  import ModalButtonFooter from './ModalButtonFooter'

  import { EventBus, MODAL_CLOSE_EVENT, MODAL_DISABLE_EVENT } from '../../../common/EventBus'
  import { store } from '../../../store'
  import router from '../../../common/router'
  import { captureException } from "@sentry/vue";
  import ModalLoading from './ModalLoading.vue'

  export default {
    name: 'NewArrangement',

    data () {
      const newArrangement = Object.assign({}, this.arrangement);
      newArrangement.title = '';
      if (!newArrangement.hasOwnProperty('description')) {
        newArrangement.description = '';
      }

      return {
        originalTitle: this.arrangement.title,
        newArrangement: newArrangement,
        error: null,
        loading: false
      }
    },

    computed: {
      valid () {
        return this.isTitleValid
      },
      isTitleValid () {
        return this.newArrangement.title.length !== 0
      }
    },

    props: {
      arrangement: Object,
      isValid: true
    },

    components: {
      ModalLoading,
      InlineSvg,
      Warning,
      CustomButton,
      ModalButtonFooter
    },

    methods: {
      setTitle (event) {
        this.newArrangement.title = event.target.value;
      },
      setDescription (event) {
        this.newArrangement.description = event.target.value;
      },
      cancel () {
        EventBus.$emit(MODAL_CLOSE_EVENT)
      },
      async createArrangement () {
        if (!this.valid) {
          return
        }

        if (this.newArrangement.title === '') {
          this.newArrangement.title = this.originalTitle
        }

        this.error = null
        this.loading = true
        EventBus.$emit(MODAL_DISABLE_EVENT);
        try {
          if (this.newArrangement.code) {
            const arrangementCode = await store.createArrangementFromArrangement(this.newArrangement.code, {
              title: this.newArrangement.title,
              description: this.newArrangement.description
            })
            this.redirectToDetail(arrangementCode)
          } else {
            const { arrangementCode } = await store.createArrangement(this.newArrangement)
            this.redirectToDetail(arrangementCode)
          }
        } catch (exception) {
          captureException(new Error(exception.message))
          this.error = exception.message
        } finally {
          this.loading = false
        }
      },
      async redirectToDetail (arrangementCode) {
        router.push({ name: 'arrangement-detail', params: { code: arrangementCode } }).catch(() => {})
      }
    }
  }
</script>
