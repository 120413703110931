var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flexbox flexbox--column flex" },
    [
      _c(
        "custom-header",
        { attrs: { "section-title": _vm.$route.meta.title } },
        [
          !_vm.isNewArrangementView
            ? _c(
                "div",
                {
                  staticClass: "section-header__button-container",
                  attrs: { slot: "buttons" },
                  slot: "buttons",
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled: _vm.store.loading,
                        color: "blue",
                        "data-test": "button.new_arrangement",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.navigateTo("new-arrangement-start")
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { name: "plus" } }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            this.$i18n.t("generic.buttons.new_arrangement")
                          ) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  this.store.hasAdminLicense
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            disabled: _vm.store.loading,
                            variant: "subtle",
                            color: "blue",
                            "data-test": "button.switch-role",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchRole()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                this.store.inAdminMode
                                  ? this.$i18n.t(
                                      "generic.buttons.switch.to_instructor"
                                    )
                                  : this.$i18n.t(
                                      "generic.buttons.switch.to_admin"
                                    )
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "arrangements-container px-3" },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _c("div", { staticClass: "arrangements-list__header py-4" }, [
              _c(
                "div",
                { staticClass: "arrangements-list__filter-container" },
                [
                  _c("a-text-input", {
                    attrs: {
                      part: "search",
                      icon: "search",
                      color: "grey",
                      "data-test": "search-input",
                      placeholder: _vm.$t("generic.input.search.placeholder"),
                      disabled: _vm.isDisabled,
                      value: _vm.searchString,
                      clearable: "",
                    },
                    on: {
                      search: _vm.handleSearchInput,
                      focus: function ($event) {
                        return _vm.$emit("focus")
                      },
                      input: _vm.handleSearchInput,
                      clear: _vm.clearSearchInput,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "arrangements-list__content flexbox flex-align-items--start justify-content-center",
            },
            [
              _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _c(
                  "div",
                  { staticClass: "arrangements-list__content-inner" },
                  [
                    _vm.isLoading
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "arrangements-index arrangements-index--empty",
                            class: _vm.arrangementsIndexClass,
                          },
                          [
                            _c("a-loader", { attrs: { variant: "page" } }, [
                              _vm._v(_vm._s(_vm.$t("generic.loading"))),
                            ]),
                          ],
                          1
                        )
                      : !_vm.store.arrangements.hasData &&
                        !_vm.isNewArrangementView
                      ? _c(
                          "transition",
                          {
                            attrs: { appear: "", name: "fade", mode: "out-in" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "arrangements-index arrangements-index--empty",
                                class: _vm.arrangementsIndexClass,
                              },
                              [
                                _c("inline-svg", {
                                  staticClass: "arrangement-index__icon",
                                  attrs: {
                                    svg: require("arrange/new-arrangement.svg"),
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "strong",
                                  { staticClass: "arrangement-index__header" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("generic.no_arrangements"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "arrangement-index__subheader",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "generic.new_arrangement_subtitle"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a-button",
                                  {
                                    attrs: { color: "blue" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.navigateTo(
                                          "new-arrangement-start"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("a-icon", { attrs: { name: "plus" } }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          this.$i18n.t(
                                            "generic.buttons.new_arrangement"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : !_vm.responseEmpty(
                          _vm.store.arrangements.searchResults
                        ) || _vm.isNewArrangementView
                      ? _c(
                          "transition-group",
                          {
                            staticClass: "arrangements-index cards-container",
                            class: _vm.arrangementsIndexClass,
                            attrs: {
                              appear: "",
                              css: true,
                              tag: "div",
                              name: "list",
                              mode: "in-out",
                              "data-test": "arrangement_container",
                            },
                          },
                          [
                            _vm.isNewArrangementView
                              ? _c("arrangement-template-item", {
                                  key: "new-arrangement",
                                  attrs: {
                                    arrangement: _vm.getNewArrangement(),
                                    highlight: _vm.searchString,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              _vm.store.arrangements.searchResults,
                              function (arrangement) {
                                return _c(_vm.listItemType, {
                                  key: arrangement.code,
                                  tag: "component",
                                  attrs: {
                                    arrangement: arrangement,
                                    highlight: _vm.searchString,
                                  },
                                })
                              }
                            ),
                          ],
                          2
                        )
                      : _c(
                          "transition",
                          {
                            attrs: { appear: "", name: "fade", mode: "out-in" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "arrangements-index arrangements-index--empty",
                                attrs: {
                                  "data-test": "arrangements-empty-container",
                                },
                              },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "arrangement-index__header" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("list.no_arrangements"))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                    _vm._v(" "),
                    _c("span", { ref: "showMoreResults" }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("modal-root"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }